<template>
	<div style="height: calc(100vh - 104px)">
		<div class="site">
			<v-img src="@/assets/error/404.svg" width="261" height="150"></v-img>
			<span style="color: #999999; font-size: 18px">抱歉，您访问的页面不存在</span>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.site {
	position: absolute;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
	left: 50%;
	text-align: center;
}
</style>
